import React from 'react'

import styled from 'styled-components'
import Link from '../components/Link'
import Colors from '../calendar/util/Colors'

const Date = styled.div`
  margin-right: 0.5rem;
`

const EventList: React.SFC<{ events: any }> = ({ events }) => {
  return events.map(event => (
    <div className="d-flex" style={{ alignItems: 'flex-start' }}>
      <Date>{event.date}</Date>

      <Link to={event.url}>{event.title},</Link>
      <div style={{ color: Colors.gray50 }} className="ml-1">
        {event.venue.name}
      </div>
    </div>
  ))
}

export default EventList
