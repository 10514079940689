import React from 'react'

import Layout from '../components/layout'
import { graphql } from 'gatsby'
import Title from '../ui/Title'
import EventList from '../components/EventList'

const TapahtumatPage = ({ data }) => {
  const events = data.allContentfulEvent.edges.map(node => node.node)
  return (
    <Layout locale="et" page={{ slug: 'tapahtuu' }}>
      <div className="container my-5">
        <Title>Tapahtumat</Title>
        <EventList events={events} />
      </div>
    </Layout>
  )
}

export const query = graphql`
  query EventsQuery {
    allContentfulEvent(
      filter: { node_locale: { eq: "et" }, done: { ne: true } }
      sort: { order: ASC, fields: [date] }
    ) {
      edges {
        node {
          title
          date(formatString: "DD.MM.YYYY")
          url
          venue {
            name
          }
        }
      }
    }
  }
`

export default TapahtumatPage
