import styled from 'styled-components'
import Colors from '../calendar/util/Colors'
export const Title = styled.h1`
  padding-bottom: 1rem;
  margin-bottom: 1rem;
  text-transform: uppercase;
  letter-spacing: 0.1rem;
  padding-bottom: 1rem;
  font-size: 2rem;
  position: relative;
  &:after {
    content: '';
    border-bottom: 4px solid ${Colors.primary};
    max-width: 120px;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
  }
  @media (max-width: 767px) {
    font-size: 1.6rem;
  }
`
export default Title
